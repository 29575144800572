/* eslint-disable jsx-a11y/iframe-has-title */
import "./App.css";
import Image1 from "./assets/img/phone.png";
import Image2 from "./assets/img/laptop.png";
import Image3 from "./assets/img/logo.png";
import video1 from "./assets/videos/mainvideo.mp4";
import { Modal } from "react-responsive-modal";
import { useState } from "react";
import "react-responsive-modal/styles.css";
function App() {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  return (
    <div className="App">
      <Modal
        classNames="reactmodelcalendar"
        styles={{ width: "600px" }}
        open={open}
        onClose={onCloseModal}
        center
      >
        <iframe
          src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ14AEgvLQ8uT-xCkBNiePfzxzgG_ElTLl91M2nifMA3kfSp5rVnmKLTqIrcFhGS-YiAcrmgy5DO?gv=true"
          style={{ border: "0" }}
          className="iframecalendar"
          width="270"
          height="500"
          frameborder="0"
        ></iframe>
      </Modal>
      <div className="Home">
        <div className="topbar">
          <div className="topbarcontainer">
            <h1>
              Join the ultimate lifestyle coaching program | Slots Available for
              april 2025
            </h1>
          </div>
        </div>
        <div className="MainContainer">
          <div className="innermaincontainer">
            <div className="MainHEading">
              <h1>
                <h2 className="MainHEadingspan">
                  Welcome to <br /> Project Fit Human
                </h2>
              </h1>
            </div>
            <div className="SubHeading">
              <h1>
                <h2 className="SubHeadingspan">
                  THe Ultimate <br />{" "}
                  <span style={{ fontWeight: "bold" }}>Lifestyle Coaching</span>{" "}
                  <br /> Program
                </h2>
              </h1>
            </div>

            <div className="VideoContainer">
              <iframe
                width="100%"
                height="570"
                src={video1}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
            <div className="Registerbutton">
              <button onClick={onOpenModal}>
                <span className="Registerbuttonspan1">BOOK YOUR SLOT</span>
                <br />
                <span className="Registerbuttonspan2">
                  FOR APRIL 2025, NOW!
                </span>
              </button>
            </div>
            <div className="MainHEading">
              <h1>
                <span className="MainHEadingspan1">Program Details</span>
              </h1>
              <h2>
                <span className="SubHeadingspan1">
                  THE FIT HUMAN <br /> 12 WEEKS & 24 WEEKS PROGRAM
                </span>
              </h2>
            </div>
            {/* <div className="SubHeading2">
              <h3 className="SubHeading2h3">
                <span> First 4 Weeks</span> | Build habits, improve metabolism,
                and establish a strong training base.
              </h3>
              <h3 className="SubHeading2h3">
                Increase intensity, refine nutrition, and push for consistent
                progress. | <span>Middle 4 Weeks</span>
              </h3>
              <h3 className="SubHeading2h3">
                <span> Final 4 Weeks</span> | Maximize results, fine-tune
                recovery, and lock in a sustainable lifestyle.
              </h3>
            </div> */}
            <div className="infocontainer1">
              <div className="infocontainer1left">
                <div className="infoimagecontainer">
                  <img src={Image1} alt="" />
                </div>
              </div>
              <div className="infocontainer1right">
                <div className="InfoContainerData">
                  <h1 className="InfoContainerDatah1">
                    <span>12 WEEKS</span> PRODUCTIVITY PROGRAM
                  </h1>
                  <h2 className="InfoContainerDatah2">
                    "The Ultimate Productivity Program to MAXIMIZE Your Full
                    Potential - Body, Mind & Performance."
                  </h2>
                  {/* <p className="InfoContainerDatap">
                    This exclusive 20-page guide gives you everything you need
                    to start your day with powerful habits that align your body
                    and mind for optimal performance. Set yourself up for a
                    healthier, more vibrant life—every day.
                  </p> */}
                  <ul className="InfoContainerDataul">
                    <li className="InfoContainerDatali">
                      <span>First 4 Weeks:</span> Foundation & Adaptation -
                      Build habits, improve metabolism, and establish a strong
                      training base.
                    </li>
                    <li className="InfoContainerDatali">
                      <span> Middle 4 Weeks:</span> Acceleration & Refinement -
                      Increase intensity, refine nutrition, and push for
                      consistent progress.
                    </li>
                    <li className="InfoContainerDatali">
                      <span>Final 4 Weeks: </span> Peak Performance &
                      Transformation - Maximize results, fine-tune recovery, and
                      lock in a sustainable lifestyle.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="joinnowbottom">
              <h1>
                <span className="">
                  AND YES YOU CAN ACHIEVE SUCH{" "}
                  <span style={{ color: "#44d62c" }}>
                    SUSTAINABLE TRANSFORMATION
                  </span>{" "}
                  IN THAT TIME
                </span>
              </h1>
            </div>
            <div className="infocontainer2">
              <div className="infocontainer2left">
                <div className="InfoContainerData">
                  <h1 className="InfoContainerDatah1">
                    <span>24 WEEKS</span> ULTIMATE TRANSFORMATION PROGRAM
                  </h1>
                  <h2 className="InfoContainerDatah2">
                    "Just a weight loss program? That’s cute.🥰 This is a
                    full-body & lifestyle transformation program."
                  </h2>

                  <ul className="InfoContainerDataul">
                    <li className="InfoContainerDatali">
                      <span>First 8 Weeks: Reset & Build :</span> Establish
                      strong habits in nutrition, training, and recovery while
                      boosting metabolism.
                    </li>
                    <li className="InfoContainerDatali">
                      <span>Second 8 Weeks: Strength & Growth : </span> Improve
                      physical strength, mental resilience, and daily
                      productivity with structured routines.
                    </li>
                    <li className="InfoContainerDatali">
                      <span>
                        Final 8 Weeks: Peak Performance & Sustainability :{" "}
                      </span>
                      Fine-tune your physique, sharpen focus, and create lasting
                      lifestyle changes.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="infocontainer2right">
                <div className="infoimagecontainer">
                  <img src={Image2} alt="" />
                </div>
              </div>
            </div>
            <div className="joinnowbottom">
              <h1>
                <span className="">
                  After 24 weeks, you won’t just see a{" "}
                  <span style={{ color: "#44d62c" }}>
                    transformation—you’ll own it.
                  </span>{" "}
                  No more trainers, no more guesswork. You’ll be your own
                  coach for life
                  <span style={{ color: "#44d62c" }}></span>{" "}
                </span>
              </h1>
            </div>
            {/* <div className="joinnowbottom">
              <h1>
                <span className="">
                  Join us for the <span>free</span> 3-Day <br /> Ultimate
                  Morning Challenge
                </span>
              </h1>
            </div> */}
            <div className="Registerbutton">
              <button onClick={onOpenModal}>
                <span className="Registerbuttonspan1">
                  I'M IN! SAVE MY SPOT!
                </span>
                <br />
                <span className="Registerbuttonspan2">
                  And Let's Turn Goals Into Reality💪
                </span>
              </button>
            </div>
            <div className="Fottercontainer">
              <h1>
                Have any questions? Email us at <span>fitxhuman@gmail.com</span>
              </h1>
              <img src={Image3} alt="" />
              <p>
                The information provided here is for general informational
                purposes only and should not be considered as medical or
                clinical advice. It is not intended to diagnose, treat, cure, or
                prevent any health condition, and should not be used as a
                substitute for professional medical advice, diagnosis, or
                treatment. Always seek the guidance of your physician or other
                qualified health provider with any questions you may have
                regarding a medical condition or health objectives. The use of
                any information provided on this site is solely at your own
                risk, and the author of this information is not liable for any
                consequences arising from its use.
              </p>
              {/* <h2>
                <span>Privacy Policy</span> | <span>Terms of Service</span>
              </h2> */}
              <h3>Copyright © 2025 Fit Human. All rights reserved.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
